//
// pricing.js
// Theme module
//

import { CountUp } from 'countup.js';

const toggles = document.querySelectorAll('[data-toggle="price"]');
const ctaButtonsMonthly = document.querySelectorAll('[data-term="monthly"]');
const ctaButtonsYearly = document.querySelectorAll('[data-term="yearly"]');
const DURATION = 1;

function showYearlyButton() {
  ctaButtonsMonthly.forEach(target => {
    target.style.display = 'none';
  });
  ctaButtonsYearly.forEach(target => {
    target.style.display = 'block';
  });
}

function showMonthlyButton() {
  ctaButtonsMonthly.forEach(target => {
    target.style.display = 'block';
  });
  ctaButtonsYearly.forEach(target => {
    target.style.display = 'none';
  });
}

toggles.forEach(toggle => {
  toggle.addEventListener('change', (e) => {
    const input = e.target;
    const isChecked = input.checked;

    const target = input.dataset.target;
    const targets = document.querySelectorAll(target);
    const targetsTerm = document.querySelectorAll(".priceterm");

    targets.forEach(target => {
      const annual = target.dataset.annual;
      const monthly = target.dataset.monthly;
      const options = target.dataset.options ? JSON.parse(target.dataset.options) : {};

      options.startVal = isChecked ? annual : monthly;
      options.duration = options.duration ? options.duration : DURATION;

      const countUp = isChecked ? new CountUp(target, monthly, options) : new CountUp(target, annual, options);

      countUp.start();
    });

    targetsTerm.forEach(target => {
      target.innerText = isChecked ? "/mo" : "/yr";
    });

    if (isChecked) {
      showMonthlyButton()
    } else {
      showYearlyButton()
    }
  });
});

